import i18n from '@/i18n'
import Store from '@/store'
import ReportHeading from '@/Utils/report-head'
import pdfMake from 'pdfmake/build/pdfmake'
import pdfFontsBn from 'pdfmake/build/vfs_fonts_bn'
import pdfFontsEn from 'pdfmake/build/vfs_fonts_en'

const exportPdfDetails = async (baseUrl, uri = '/report-heading/detail', orgId, reportTitle, data, vm, total, remaining, ministry, badc, dealer) => {
    try {
      Store.commit('mutateCommonProperties', {
        loading: true
      })
      if (i18n.locale === 'bn') {
        pdfMake.vfs = pdfFontsBn.pdfMake.vfs
      } else {
          pdfMake.vfs = pdfFontsEn.pdfMake.vfs
      }
        const reportHeadData = await ReportHeading.getReportHead(baseUrl, uri, orgId)
        const waterMarkText = i18n.locale === 'bn' ? 'কৃষি মন্ত্রণালয়' : 'Ministry of Agriculture'
        const pdfContent = [
          {
              columns: reportHeadData.reportHeadColumn
          },
          { text: reportHeadData.address, style: 'header3', alignment: 'center' }
        ]

        if (reportHeadData.projectName) {
          pdfContent.push({ text: reportHeadData.projectName, style: 'header3', alignment: 'center' })
        }

        pdfContent.push({ text: reportTitle, style: 'header2', alignment: 'center', decoration: 'underline' })
        pdfContent.push(
          {
            table: {
              style: 'tableData',
              headerRows: 0,
              widths: ['*', '*', '*', '*'],
              body: [
                [
                  { text: vm.$t('globalTrans.fiscalYear'), style: 'th' },
                  { text: vm.$i18n.locale === 'bn' ? data.fiscal_year_bn : data.fiscal_year, style: 'td' },
                  { text: vm.$t('fertilizerSales.month'), style: 'th' },
                  { text: vm.$i18n.locale === 'bn' ? data.month_bn : data.month_en, style: 'td' }
                ],
                [
                  { text: vm.$t('globalTrans.division'), style: 'th' },
                  { text: vm.$i18n.locale === 'bn' ? data.division_bn : data.division, style: 'td' },
                  { text: vm.$t('globalTrans.district'), style: 'th' },
                  { text: (i18n.locale === 'bn') ? data.district_bn : data.district, style: 'td' }
                ],
                [
                  { text: vm.$t('fertilizerSales.allocationType'), style: 'th' },
                  { text: vm.$i18n.locale === 'en' ? data.allocation : data.allocation_bn, style: 'td' },
                  { text: vm.$t('fertilizerSales.memo_no'), style: 'th' },
                  { text: data.memo_no, style: 'td' }
                ],
                [
                  { text: vm.$t('movement.allocationDate'), style: 'th' },
                  { text: data.allocalion_date, style: 'td' },
                  { text: '', style: 'td' },
                  { text: '', style: 'td' }
                ]
              ]
            }
          }
        )
        pdfContent.push({ text: '', style: 'fertilizer', alignment: 'center' })
        var allRow = []
        var tabledata1 = [
            { text: vm.$t('fertilizerSales.ministryAmount') + ' : ' + ministry, style: 'th', colSpan: 4, alignment: 'center' },
            {},
            {},
            {},
            { text: vm.$t('fertilizerSales.badcAllotment') + ' : ' + badc, style: 'th', colSpan: 2, alignment: 'center' },
            {}
          ]
        allRow.push(tabledata1)

        var row = [
          { text: vm.$t('globalTrans.sl_no'), style: 'th', colSpan: 1, alignment: 'center' },
          { text: vm.$t('fertilizerSales.dealerName'), style: 'th', colSpan: 3, alignment: 'center' },
          {},
          {},
          { text: vm.$t('fertilizerSales.quantity'), style: 'th', colSpan: 2, alignment: 'center' },
          {}
        ]
        allRow.push(row)

        dealer.map((delr, index) => {
          var rows = [
            { text: vm.$n(index + 1), style: 'td', colSpan: 1, alignment: 'center' },
            { text: vm.$i18n.locale === 'en' ? delr.name : delr.name_bn, style: 'td', colSpan: 3, alignment: 'center' },
            {},
            {},
            { text: data.dealerInfo[index].quantity ? vm.$n(data.dealerInfo[index].quantity) : null, style: 'td', colSpan: 2, alignment: 'center' },
            {}
          ]
          allRow.push(rows)
        })

        row = [
          { text: vm.$t('sitePreference.total') + ':', style: 'td', colSpan: 4, alignment: 'right' },
          {},
          {},
          {},
          { text: vm.$n(total), style: 'td', colSpan: 2, alignment: 'center' },
          {}
        ]
        allRow.push(row)

        row = [
          { text: vm.$t('fertilizerSales.remaining') + ':', style: 'td', colSpan: 4, alignment: 'right' },
          {},
          {},
          {},
          { text: vm.$n(remaining), style: 'td', colSpan: 2, alignment: 'center' },
          {}
        ]
        allRow.push(row)

        pdfContent.push(
            {
              table: {
                  headerRows: 0,
                  style: 'header2',
                  widths: '*',
                  body: allRow
              }
            }
        )
        var docDefinition = {
        content: pdfContent,
        pageSize: 'A4',
        pageOrientation: 'Portrait',
        watermark: { text: waterMarkText, color: 'blue', opacity: 0.1, bold: true, italics: false },
        styles: {
            th: {
              bold: true,
              fontSize: 8,
              margin: [3, 3, 3, 3]
            },
            td: {
              fontSize: 8,
              margin: [3, 3, 3, 3]
            },
            header: {
              fontSize: 12,
              margin: [0, 0, 0, 4]
            },
            header2: {
              fontSize: 10,
              margin: [0, 10, 0, 20]
            },
            fertilizer: {
              fontSize: 10,
              margin: [10, 10, 0, 20]
            },
            headerPort1: {
              fontSize: 10,
              margin: [0, 20, 0, 0]
            },
            headerPort: {
              fontSize: 10,
              margin: [0, 4, 0, 15]
            },
            header3: {
            fontSize: 9,
            margin: [0, 0, 0, 4]
            },
            tableSubHead: {
              margin: [0, 5, 0, 15]
            }
          }
        }
        pdfMake.createPdf(docDefinition, null, null, null).download('dealer-wise-fertilizer-allocation')
    } catch (error) {
      if (error) {}
    }
    Store.commit('mutateCommonProperties', {
      loading: false
    })
}
export default {
  exportPdfDetails
}
