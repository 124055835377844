<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12" sm="12">
        <iq-card>
          <template v-slot:body>
            <b-row>
              <b-col>
                <list-report-head :base-url="seedFertilizerServiceBaseUrl" uri="fertilizer/config/report-heading/detail" :org-id="3">
                  {{ $t('fertilizerSales.dealerWiseFertilizerAllocation') }}
                </list-report-head>
              </b-col>
            </b-row>
            <b-row>
              <b-col lg="12" sm="12">
                <b-table-simple class="mt-3" hover bordered small caption-top responsive>
                  <tbody>
                    <tr>
                      <th>{{ $t('globalTrans.fiscalYear') }}</th>
                      <td>{{ $i18n.locale === 'bn' ? formData.fiscal_year_bn : formData.fiscal_year }}</td>
                      <th>{{ $t('fertilizerSales.month') }}</th>
                      <td>{{ $i18n.locale === 'bn' ? formData.month_bn : formData.month_en }}</td>
                    </tr>
                    <tr>
                      <th>{{ $t('globalTrans.division') }}</th>
                      <td>{{ $i18n.locale === 'bn' ? formData.division_bn : formData.division }}</td>
                      <th>{{ $t('globalTrans.district') }}</th>
                      <td>{{ $i18n.locale === 'bn' ? formData.district_bn : formData.district }}</td>
                    </tr>
                    <tr>
                      <th>{{ $t('fertilizerSales.allocationType') }}</th>
                      <td>{{ currentLocale === 'en' ? formData.allocation : formData.allocation_bn }}</td>
                      <th>{{$t('fertilizerSales.memo_no') }}</th>
                      <td>{{ currentLocale === 'en' ? formData.memo_no : formData.memo_no }}</td>
                    </tr>
                    <tr>
                      <th>{{ $t('movement.allocationDate') }}</th>
                      <td>{{ formData.allocalion_date | dateFormat }}</td>
                      <th></th>
                      <td></td>
                    </tr>
                  </tbody>
                </b-table-simple>
              </b-col>
            </b-row>
            <b-row>
             <b-col lg="12" sm="12">
              <b-table-simple class="mt-3" hover bordered small caption-top responsive>
                  <b-thead head-variant="secondary">
                      <b-tr>
                          <b-th colspan="4" class="text-center">{{ $t('fertilizerSales.ministryAmount') + ' : ' }} {{ ministry_allotment }}</b-th>
                          <b-th :colspan="fertilizerNameList.length" class="text-center">{{ $t('fertilizerSales.badcAllotment') + ' : ' }} {{ badc_allotment }}</b-th>
                      </b-tr>
                      <b-tr>
                          <b-th colspan="1" class="text-center">{{ $t('globalTrans.sl_no') }}</b-th>
                          <b-th :colspan="fertilizerNameList.length" class="text-center">{{ $t('fertilizerSales.dealerName') }}</b-th>
                          <b-th class="text-center">{{ $t('fertilizerSales.quantity') }}</b-th>
                      </b-tr>
                      <b-tr v-for="(dealer, index) in dealerList" :key="index" >
                          <b-td colspan="1" class="text-center">{{ $n(index + 1) }}</b-td>
                          <b-td :colspan="fertilizerNameList.length" class="text-center">{{ currentLocale === 'en' ? dealer.name : dealer.name_bn }}</b-td>
                          <b-td class="text-center">{{ $n(formData.dealerInfo[index].quantity) }}</b-td>
                      </b-tr>
                    <b-tr>
                        <b-td colspan="4" class="text-right">{{ $t('fertilizerSales.total_allotment') }}:</b-td>
                        <b-td :colspan="fertilizerNameList.length" class="text-center">{{ $n(total) }}</b-td>
                    </b-tr>
                    <b-tr>
                        <b-td colspan="4" class="text-right">{{ $t('fertilizerSales.total_remaining') }}:</b-td>
                        <b-td :colspan="fertilizerNameList.length" class="text-center">{{ $n(remaining) }}</b-td>
                    </b-tr>
                  </b-thead>
                </b-table-simple>
             </b-col>
            </b-row>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import RestApi, { seedFertilizerServiceBaseUrl } from '@/config/api_config'
import { getAllocationInfo } from '../../../api/routes'
import ListReportHead from '@/components/custom/ListReportHead.vue'
import ExportPdf from './export-pdf_details'

export default {
  props: ['id'],
  components: {
    ListReportHead
  },
  async created () {
    if (this.id) {
      this.searchData = true
      const tmp = this.getFormData()
      const temps = Object.assign(this.formData, tmp)
      this.formData = temps
      this.search()
    }
  },
  mounted () {
    core.index()
  },
  data () {
    return {
      saveBtnName: this.id ? this.$t('globalTrans.update') : this.$t('globalTrans.save'),
      seedFertilizerServiceBaseUrl: seedFertilizerServiceBaseUrl,
      formData: {
        id: '',
        district_id: 0,
        division_id: 0,
        fertilizer_id: 0,
        allocation_date: '',
        dealerInfo: [],
        fiscal_year_id: 0,
        allocation_type_id: 0,
        month: 0,
        memo_no: ''
      },
      index: '',
      dealerList: [],
      total: 0,
      remaining: 0,
      ministry_allotment: '',
      badc_allotment: '',
      searchData: false,
      ministry: []
    }
  },
  computed: {
    districtList: function () {
        return this.$store.state.commonObj.districtList.filter(item => item.status === 0)
    },
    fiscalYearList: function () {
      const fiscalYears = this.$store.state.ExternalUserIrrigation.commonObj.fiscalYearList.filter(item => item.status === 0)
      const tempData = fiscalYears.map(item => {
        if (this.$i18n.locale === 'bn') {
          return { text: item.text_bn, value: item.value }
        } else {
          return { text: item.text_en, value: item.value }
        }
      })
      return tempData
    },
    allocationTypeList: function () {
      const type = this.$store.state.SeedsFertilizer.commonObj.allocationTypeList.filter(item => item.status === 1)
      const tempData = type.map(item => {
        if (this.$i18n.locale === 'bn') {
          return { text: item.text_bn, value: item.value }
        } else {
          return { text: item.text_en, value: item.value }
        }
      })
      return tempData
    },
    monthList: function () {
      return this.$store.state.ExternalUserIrrigation.commonObj.monthListfiscalYear
    },
    fertilizerNameList: function () {
        return this.$store.state.ExternalUserIrrigation.commonObj.fertilizerNameList
    },
    currentLocale: function () {
      return this.$i18n.locale
    }
  },
  methods: {
    search () {
      RestApi.getData(seedFertilizerServiceBaseUrl, getAllocationInfo, this.formData).then(response => {
        if (response.success) {
            this.searchData = true
            this.ministry_allotment = response.ministry_allocation_amount
            this.badc_allotment = response.badc_allocation_amount
            this.dealerList = response.dealer_list
            var dealerData = []
            if (this.id) {
              var total = 0
              this.formData.dealer_allocation_details.forEach((dealer, dealerIndex) => {
                var item = ''
                  if (dealer.quantity) {
                    total += parseInt(dealer.quantity)
                    item = {
                        dealer_id: dealer.dealer_id,
                        dealer_allo_details_id: dealer.id,
                        dealer_allocations_id: dealer.dealer_allocations_id,
                        quantity: dealer.quantity
                    }
                  } else {
                    item = {
                        dealer_id: dealer.dealer_id,
                        dealer_allo_details_id: dealer.id,
                        dealer_allocations_id: dealer.dealer_allocations_id,
                        quantity: 0
                    }
                  }
                dealerData.push(item)
              })
              this.total = total
              this.remaining = this.badc_allotment - total
            } else {
              this.dealerList.forEach((dealer, dealerIndex) => {
                const item = {
                    dealer_id: dealer.id,
                    dealer_name: this.$i18n.locale === 'en' ? dealer.name : dealer.name_bn,
                    quantity: ''
                }
                dealerData.push(item)
              })
            }
          this.formData.dealerInfo = dealerData
        }
      })
    },
    getFormData () {
      const tmpData = this.$store.state.list.find(item => item.id === this.id)
      return JSON.parse(JSON.stringify(tmpData))
    },
    getFormattedDate (date) {
      const dateArr = date.split('-')
      return this.$n(parseInt(dateArr[0]), { useGrouping: false }) + '-' + this.$n(parseInt(dateArr[1]), { useGrouping: false }) + '-' + this.$n(parseInt(dateArr[2]), { useGrouping: false })
    },
    pdfExport () {
    const reportTitle = this.$t('fertilizerSales.dealerWiseFertilizerAllocation')
    ExportPdf.exportPdfDetails(seedFertilizerServiceBaseUrl, '/fertilizer/config/report-heading/detail', 3, reportTitle, this.formData, this, this.total, this.remaining, this.ministry_allotment, this.badc_allotment, this.dealerList)
    }
  }
}
</script>
