<template>
    <b-container fluid>
        <!-- <iq-card>
            <template v-slot:body>
                <b-row>
                   <b-col lg="6" sm="12">
                    <ValidationProvider name="Requistion No"  vid="memo_no" rules="">
                      <b-form-group
                          class="row"
                          label-cols-sm="4"
                          label-for="memo_no"
                          slot-scope="{ valid, errors }"
                      >
                        <template v-slot:label>
                          {{$t('externalUserIrrigation.requisition_no')}}<span class="text-danger"></span>
                        </template>
                        <b-form-input
                          plain
                          v-model="search.memo_no"
                          id="memo_no"
                          :state="errors[0] ? false : (valid ? true : null)"
                          >
                          </b-form-input>
                      </b-form-group>
                    </ValidationProvider>
                  </b-col>
                   <b-col lg="6" sm="12">
                          <ValidationProvider name="Fiscal Year"  vid="fiscal_year_id" rules="">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="fiscal_year_id"
                                slot-scope="{ valid, errors }"
                            >
                              <template v-slot:label>
                                {{$t('globalTrans.fiscalYear')}}<span class="text-danger"></span>
                              </template>
                              <b-form-select
                                plain
                                v-model="search.fiscal_year_id"
                                :options="fiscalYearList"
                                id="fiscal_year_id"
                                :state="errors[0] ? false : (valid ? true : null)"
                                >
                                <template v-slot:first>
                                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                                </b-form-select>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="6" sm="12">
                          <ValidationProvider name="Fertilizer Name"  vid="fertilizer_id" rules="">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="fertilizer_id"
                                slot-scope="{ valid, errors }"
                            >
                              <template v-slot:label>
                                {{$t('movement.fertilizerName')}}  <span class="text-danger"></span>
                              </template>
                              <b-form-select
                                plain
                                v-model="search.fertilizer_id"
                                :options="fertilizerNameList"
                                id="fertilizer_id"
                                :state="errors[0] ? false : (valid ? true : null)"
                                >
                                <template v-slot:first>
                                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                                </b-form-select>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="6" sm="12">
                          <ValidationProvider name="Month"  vid="month_id" rules="">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="month_id"
                                slot-scope="{ valid, errors }"
                            >
                              <template v-slot:label>
                                {{$t('fertilizerSales.month')}}  <span class="text-danger"></span>
                              </template>
                              <b-form-select
                                plain
                                v-model="search.month"
                                :options="monthList"
                                id="month_id"
                                :state="errors[0] ? false : (valid ? true : null)"
                                >
                                <template v-slot:first>
                                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                                </b-form-select>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                    <b-col class="col-sm-2">
                        <b-button type="button" variant="primary" @click="searchData">{{ $t('globalTrans.search')}}</b-button>
                    </b-col>
                </b-row>
            </template>
        </iq-card> -->
        <b-row>
            <b-col md="12">
                <iq-card>
                    <template v-slot:headerTitle>
                        <h4 class="card-title">{{$t('fertilizerSales.dealerWiseFertilizerAllocation')}}</h4>
                    </template>
                    <template v-slot:headerAction>

                    </template>
                    <template v-slot:body>
                        <b-overlay :show="loadingState">
                            <b-row>
                                <b-col md="12" class="table-responsive">
                                    <b-table bordered hover :items="listData" :fields="columns" aria-hidden="loading | listReload ? 'true' : null" :emptyText="$t('globalTrans.noDataFound')" show-empty>
                                        <template v-slot:cell(index)="data">
                                        {{ $n(data.index + pagination.slOffset) }}
                                        </template>
                                        <template v-slot:cell(requisition_qty)="data">
                                          {{ $n(data.item.requisition_qty, { useGrouping: false }) }}
                                        </template>
                                        <template v-slot:cell(allocation_qty)="data">
                                          {{ $n(data.item.allocation_qty, { useGrouping: false }) }}
                                        </template>
                                        <template v-slot:cell(stock_qty)="data">
                                          {{ $n(data.item.stock_qty, { useGrouping: false }) }}
                                        </template>
                                    </b-table>
                                </b-col>
                            </b-row>
                        </b-overlay>
                    </template>
                </iq-card>
            </b-col>
        </b-row>
        <b-modal id="modal-5" size="lg" :title="$t('fertilizerSales.dealerWiseFertilizerAllocation') + ' ' + $t('globalTrans.details')" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
            <template #modal-title class="flex-grow-1">
            {{ $t('fertilizerSales.dealerWiseFertilizerAllocation') + ' ' + $t('globalTrans.details') }}
            <b-button variant="primary" @click="pdfExport" class="mr-2 float-right">
              {{  $t('globalTrans.export_pdf') }}
            </b-button>
          </template>
            <Details :id="editItemId" :key="editItemId"  ref="details"/>
        </b-modal>
    </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import Details from './Details'
import RestApi, { seedFertilizerServiceBaseUrl } from '@/config/api_config'
import { dealerAllocationList } from '../../../api/routes'
import ModalBaseMasterList from '@/mixins/modal-base-master-list'

export default {
    mixins: [ModalBaseMasterList],
    components: {
        Details
    },
    data () {
        return {
            search: {
                memo_no: '',
                fiscal_year_id: 0,
                month: 0,
                fertilizer_id: 0
            }
        }
    },
    computed: {
        fiscalYearList: function () {
          const fiscalYears = this.$store.state.ExternalUserIrrigation.commonObj.fiscalYearList.filter(item => item.status === 0)
          const tempData = fiscalYears.map(item => {
            if (this.$i18n.locale === 'bn') {
              return { text: item.text_bn, value: item.value }
            } else {
              return { text: item.text_en, value: item.value }
            }
          })
          return tempData
        },
        allocationTypeList: function () {
            const type = this.$store.state.SeedsFertilizer.commonObj.allocationTypeList.filter(item => item.status === 1)
            const tempData = type.map(item => {
            if (this.$i18n.locale === 'bn') {
                return { text: item.text_bn, value: item.value }
            } else {
                return { text: item.text_en, value: item.value }
            }
            })
            return tempData
        },
        monthList: function () {
          return this.$store.state.ExternalUserIrrigation.commonObj.monthListfiscalYear
        },
        fertilizerNameList: function () {
            return this.$store.state.ExternalUserIrrigation.commonObj.fertilizerNameList
        },
        formTitle () {
            return this.editItemId === 0 ? this.$t('fertilizerSales.dealerWiseFertilizerAllocation') + ' ' + this.$t('globalTrans.entry') : this.$t('fertilizerSales.dealerWiseFertilizerAllocation') + ' ' + this.$t('globalTrans.update')
        },
        columns () {
            const labels = [
                { label: this.$t('globalTrans.sl_no'), class: 'text-left' },
                { label: this.$t('movement.fertilizerName'), class: 'text-left' },
                { label: this.$t('externalUserIrrigation.requisition_amount'), class: 'text-left' },
                { label: this.$t('externalUserIrrigation.allotment_amount'), class: 'text-left' },
                { label: this.$t('externalUserIrrigation.stock_balance'), class: 'text-left' }
            ]

            /*eslint-disable */
            let keys = []

            if (this.$i18n.locale === 'bn') {
                keys = [
                { key: 'index' },
                { key: 'fertilizer_name_bn' },
                { key: 'requisition_qty' },
                { key: 'allocation_qty' },
                { key: 'stock_qty' }
                ]
            } else {
                keys = [
                { key: 'index' },
                { key: 'fertilizer_name' },
                { key: 'requisition_qty' },
                { key: 'allocation_qty' },
                { key: 'stock_qty' }
                ]
            }

            return labels.map((item, index) => {
                return Object.assign(item, keys[index])
            })
        }
    },
    async created () {
        this.loadData()
    },
    mounted () {
        core.index()
    },
    methods: {
        details (item) {
          this.editItemId = item.id
        },
        dataChange () {
            this.loadData()
        },
        async searchData () {
            this.loadData()
        },
        loadData () {
            const params = Object.assign({}, this.search, {page: this.pagination.currentPage, per_page: this.pagination.perPage })
            this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
            RestApi.getData(seedFertilizerServiceBaseUrl, dealerAllocationList, params).then(response => {
                if (response.success) {
                    this.$store.dispatch('setList', response.data)
                }
                this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
            })
        },
        getCustomDataList(data) {
          const listData = data.map(item => {
            const fiscalObj = this.$store.state.ExternalUserIrrigation.commonObj.fiscalYearList.find(doc => doc.value === parseInt(item.fiscal_year_id))
            const fiscalData = {}
            if(typeof fiscalObj !== 'undefined') {
              fiscalData.fiscal_year = fiscalObj.text_en
              fiscalData.fiscal_year_bn = fiscalObj.text_bn
            } else {
              fiscalData.fiscal_year = ''
              fiscalData.fiscal_year_bn = ''
            }
            const monthObj = this.monthList.find(doc => doc.value === parseInt(item.month))
            const monthData = {}
            if(typeof monthObj !== 'undefined') {
              monthData.month_en = monthObj.text
              monthData.month_bn = monthObj.text
            } else {
              monthData.month_en = ''
              monthData.month_bn = ''
            }

            const divisionObj = this.divisionList.find(doc => doc.value === item.division_id)
            const divisionData = {}
            if(typeof divisionObj !== 'undefined') {
              divisionData.division = divisionObj.text_en
              divisionData.division_bn = divisionObj.text_bn
            } else {
              divisionData.division = ''
              divisionData.division_bn = ''
            }

            const districtObj = this.districtList.find(doc => doc.value === item.district_id)
            const districtData = {}
            if(typeof districtObj !== 'undefined') {
              districtData.district = districtObj.text_en
              districtData.district_bn = districtObj.text_bn
            } else {
              districtData.district = ''
              districtData.district_bn = ''
            }

            const allocationObj = this.allocationTypeList.find(doc => doc.value === item.allocation_type_id)
            const allocationData = {}
            if(typeof allocationObj !== 'undefined') {
              allocationData.allocation = allocationObj.text
              allocationData.allocation_bn = allocationObj.text
            } else {
              allocationData.allocation = ''
              allocationData.allocation_bn = ''
            }

            const fertilizerObj = this.fertilizerNameList.find(doc => doc.value === item.fertilizer_id)
            const fertilizerData = {}
            if(typeof fertilizerObj !== 'undefined') {
              fertilizerData.fertilizer = fertilizerObj.text
              fertilizerData.fertilizer_bn = fertilizerObj.text
            } else {
              fertilizerData.fertilizer = ''
              fertilizerData.fertilizer_bn = ''
            }

            return Object.assign({}, item, fiscalData, monthData, divisionData, districtData, allocationData, fertilizerData)
          })
          return listData
        },
        pdfExport () {
          this.$refs.details.pdfExport()
        }
    }
}
</script>

<style>
  .modal-title {
    flex-grow: 1 !important;
  }
</style>
